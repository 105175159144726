
import { uploadFile } from '@/api/attachedFile';
import {
addPost,
getPost,
updatePost,
updateSecretPost,
} from '@/api/post';
import {
  getChallengeCategory,
  addChallengeRecord,
  getChallengeRecordDetail,
  updateChallengeRecord,
  deleteChallengeRecord,
} from '@/api/challenge';
import SummerNote from '@/components/SummerNote/index.vue';
import BoardField from '@/components/board/field.vue';
import { UserModule } from '@/store/modules/user';
import { Form } from 'element-ui';
import { getToken } from '@/utils/cookies';
import {
Component,
Vue,
} from 'vue-property-decorator';

@Component({
  name: 'BoardIndex',
  components: {
    BoardField,
    SummerNote,
  },
})
export default class extends Vue {
  mounted() {
    this.init();
		this.getChallengeCategory();
  }

  get requestHeaders() {
    return {
      Authorization: `Bearer ${getToken()}`,
    };
  }

  get isAdmin() {
    return UserModule.roles.indexOf('ROLE_ADMIN') > -1;
  }

  private apiUrl = process.env.VUE_APP_BASE_API;

  private board = {
    challengeUid: '',
    fieldList: [],
    categoryList: [],
    fileSizeLimit: 0,
    fileCountLimit: 0,
  };

  private formData: any = {
    challengeUid: '',
    title: '',
    content: '',
    categoryUid: '',
    fileList: [],
  };

  private categoryValidator: any = (rules: any, value: any, callback: Function) => {
    // if (this.selectCategory.length < 1) {
      // callback(new Error('분류를 선택하세요.'));
    // }
    // callback();
  };

  private rules = {
    // title: [
      // { required: true, message: '제목을 입력하세요.', trigger: 'blur' },
    // ],
    // categoryList: [
      // { validator: this.categoryValidator, trigger: 'blur' },
    // ],
    writer: [
      { required: true, message: '작성자를 입력하세요.', trigger: 'blur' },
    ],
  };

  private loading = false;

  private selectCategory: any = {};

  private uid: string = this.$route.params.uid;

  private init() {
    this.formData = {
      challengeUid: '',
      content: '',
      categoryList: [],
      fileList: [],
    };
    this.loading = true;
    this.board.challengeUid = this.$route.params.challengeUid;
    this.formData.challengeUid = this.$route.params.challengeUid;
    this.loading = false;

    if (this.uid) {
      this.getChallengeRecordDetail();
    }
  }

  private getChallengeCategory() {
		getChallengeCategory().then((res) => {
			this.board.categoryList = res.data;
		}).catch(() => {
			this.$message.warning('챌린지 리스트를 조회하는데 실패했습니다.');
		});
		this.loading = false;
	}

  private getChallengeRecordDetail() {
		getChallengeRecordDetail(this.uid).then((res) => {
      this.formData = res.data;

      let postFile = {};
      postFile = [{
        file: res.data.fileList[0].file,
        fileUid: res.data.fileList[0].fileUid,
        challengeUid: this.$route.params.challengeUid,
        url: `${this.apiUrl}/attached-file/${res.data.fileList[0].fileUid}`,
        viewOrder: 0,
      }];
      this.formData.fileList = postFile;
		}).catch(() => {
			this.$message.warning('챌린지 작성글을 조회하는데 실패했습니다.');
		});
		this.loading = false;
	}

  private handleClickSubmit() {
    (this.$refs.noticeForm as Form).validate((valid: boolean) => {
      if (valid) {
        this.loading = true;
          if (this.formData.fileList.length === 0) {
            this.$message.warning('사진을 첨부하세요.');
            this.loading = false;
          } else {
          console.log(1);
          if (this.uid) {
            updateChallengeRecord(this.uid, this.formData).then((res: any) => {
              this.loading = false;
              this.$router.push({ name: 'ChallengeDetail', params: { challengeUid: this.$route.params.challengeUid } });
            }).catch((error) => {
              alert(error.response.data.message || '챌린지 수정을 작성하는데 실패했습니다.');
            this.loading = false;
          });
          } else {
            addChallengeRecord(this.formData).then((res: any) => {
              this.loading = false;
              this.$router.push({ name: 'ChallengeDetail', params: { challengeUid: this.$route.params.challengeUid } });
            }).catch((error) => {
              alert(error.response.data.message || '챌린지 기록을 작성하는데 실패했습니다.');
            this.loading = false;
          });
            }
        }
      }
    });
  }

  private handleClickRemove() {
    this.loading = true;
    this.$confirm('정말 챌린지 기록을 삭제하시겠습니까?', {
      confirmButtonText: '삭제',
      cancelButtonText: '취소',
    }).then(() => {
      deleteChallengeRecord(this.uid).then((res: any) => {
        this.$message.warning('챌린지 기록을 삭제하였습니다.');
        this.$router.push({ name: 'ChallengeDetail', params: { challengeUid: this.$route.params.challengeUid } });
      }).catch((error) => {
        alert(error.response.data.message || '챌린지 기록을 작성하는데 실패했습니다.');
      });
    });
    this.loading = false;
  }

  /* eslint-disable */
  private handleClickCancle() {
      this.$router.push({ name: 'Challenge' });
  }
  /* eslint-enable */

  private handleChangeContents(value: string) {
    this.formData.content = value;
  }

  private handleExceed(files: any, fileList: any[]) {
    this.$message.warning('최대 첨부가능한 수는 1개 입니다.');
  }

  private handleBeforeUpload(uploadFile2:any, isPhoto:boolean) {
    const fileSizeLimitByMb = 10 * 1024 * 1024;
    if (isPhoto) {
      const isImageFile = uploadFile2.type.split('/')[0] === 'image';
      if (!isImageFile) {
        this.$message.warning('이미지 파일만 업로드 가능합니다.');
        return false;
      }
    }
    if (uploadFile2.size > fileSizeLimitByMb) {
      this.$message.warning('파일 업로드 최대용량은 10MB입니다.');
      return false;
    }
    if (this.formData.fileList.length >= 1) {
      this.$message.warning('최대 1개까지 업로드 가능합니다.');
      return false;
    }
    return true;
  }

  private handleSuccessUploadImageFile(data: any) {
    let postFile = {};
      postFile = {
        file: data,
        fileUid: data.uid,
        challengeUid: this.board.challengeUid,
        viewOrder: 0,
      };
    this.formData.fileList.push(postFile);
  }

  private handleRemoveImageFile(fileUid: string) {
    const fileIndex = this.formData.fileList.findIndex((postFile: any) => postFile.fileUid === fileUid);
    console.log(fileUid);
    if (fileIndex > -1) this.formData.fileList.splice(fileIndex, 1);
  }
}
